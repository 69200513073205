import React from 'react';
import Logo from './logo.svg';

const SVGLogo = () => {
  return (
    <img src={Logo} alt='a beautiful portait of me' />
  );
};

export default SVGLogo;
